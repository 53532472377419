.module--grid {
  margin-bottom: 130px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: $gut;
  column-gap: $gut;

  @include grid-breakpoint--xs {
    grid-template-columns: repeat(2, 1fr);
  }

  @include grid-breakpoint--m {
    grid-template-columns: repeat(3, 1fr);
  }

}

.grid__item {
  aspect-ratio: 1/1;
  position: relative;
}

.grid__item--button {
  padding: 15px;
}

.grid__item--bronze {
  background-color: $colorBronze;
}