// * Grid
$col: 122px;
$gut: 16px;
$content-padding: 20px;

// * Dimensions
$main-width: 12*$col + 11*$gut;
$content-width: $main-width + 2*$content-padding;

$col12: 12*$col + 11*$gut;
$col10: 10*$col + 9*$gut;
$col9: 9*$col + 8*$gut;
$col8: 8*$col + 7*$gut;
$col7: 7*$col + 6*$gut;
$col6: 6*$col + 5*$gut;
$col4: 4*$col + 3*$gut;
$col3: 3*$col + 2*$gut;
$col2: 2*$col + 1*$gut;
$col1: 1*$col + 1*$gut;