$navTransform: all .5s ease;

.navtoggle {
  cursor: pointer;
}

.hamburger {
  display: block;
  cursor: pointer;
  width: 24px;
  width: 30px;
  height: 16px;
  height: 24px;
  position: relative;
}

.hamburger span {
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: $colorBronze;
  border-radius: 2px;
  left: 0;
  transition: all .3s ease;
}

.hamburger span:nth-child(1),
.hamburger span:nth-child(3) {
  top: 8px;
  top: 10px;
}

//* the plus
.hamburger span:nth-child(3) {
  transform: rotate(90deg);
  opacity: 0;
}

.hamburger span:nth-child(2) {
  top: 0;
}

.hamburger span:nth-child(4) {
  top: 16px;
  top: 20px;
}

/* Animation */

.navtoggle.active {

  .hamburger {
    span {
      background-color: $colorBronze;
    }
    span:nth-child(1) {
      transform: rotate(45deg);
    }
    span:nth-child(2),
    span:nth-child(4) {
      width: 0;
      opacity: 0;
    }
    span:nth-child(3) {
      // top: 13px;
      transform: rotate(-45deg);
      opacity: 1;
    }
  }

}