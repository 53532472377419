.navtoggle {
  z-index: 20;
  position: absolute;
  top: $content-padding;
  right: $content-padding;
  &.active {
    position: fixed;
  }

  @include nav-breakpoint--s {
    display: none;
  }

}

.globalheader {
  padding: 48px 0 60px 0;

  @include nav-breakpoint--s {

  }

  @include nav-breakpoint--m {
    display: grid;
    grid-template-columns: 260px 1fr;
    align-items: center;
  }

}

.globalheader__homelink {
  display: block;
  margin: 0 auto;
  width: 260px;
  max-width: 60%;

  @include nav-breakpoint--s {
    max-width: 100%;
    margin: 0 auto 25px auto;
  }

  @include nav-breakpoint--m {
    margin: 0;
  }

}