@mixin hyphenate(){
   -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

@mixin nohyphenate {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
}