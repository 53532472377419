//* Layout

.globalfooter__adress {
  grid-area: adress;
}

.globalfooter__utility {
  grid-area: utility;
}

.globalfooter__social {
  grid-area: social;
}

.globalfooter {
  text-transform: lowercase;
  border-top: 2px solid $colorGrey;
  padding: 40px 0 60px 0;

  display: grid;
  column-gap: $gut;
  row-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "adress adress adress"
    "utility utility utility"
    "social social social";

  @include footer-breakpoint--s {
    grid-template-areas:
    "adress adress utility utility"
    "social social . .";
  }

  @include footer-breakpoint--m {
    grid-template-areas:
    "adress utility social .";
  }

}

//* Logo
.globalfooter__homelink {
  display: block;
  width: 164px;
  margin-bottom: 24px;
}

//* Typography
.globalfooter {
  font-size: rem(25px);
  line-height: 1.6;
  h3 {
    margin-bottom: 10px;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
  a:hover {
    color: $colorWhite;
  }
}

//* Social
.sociallist__item--footer {
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
}

.sociallist__link--footer {
  width: 69px;
}

//* Newsletter
.form-newsletter--footer {
  @media only screen and (min-width: 600px) {
    max-width: $col3;
  }
}