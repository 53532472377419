.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form__input {
  line-height: 1;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
    width: 100%;
    padding: 2px 8px;
    background-color: $colorGrey  ;
    border: 1px solid #707070;
    color: $colorBronze;

    &:focus {
        outline: none;
    }
    @include placeholder {
      color: $colorBronze;
    }
}

.form__submit {
  text-transform: lowercase;
  cursor: pointer;
    line-height: 2;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border: none;
    background-color: transparent;
    padding: 0;
    padding-top: 7px;
    color: $colorBronze;
    outline: none;
    transition: all .3s ease;

    &:hover {
        color: $colorWhite;
    }
}

.feedback {
    margin-top: 10px;
    line-height: 1.2;
}

.error {
    color: red;
}

.success {
    color: $colorWhite;
}