.sociallist {
  display: flex;
}

.sociallist__link {
  display: block;
  aspect-ratio: 1/1;
  transition: all .3s ease;
  &:hover {
    transform: scale(1.1);
  }
}