.button__link {

  aspect-ratio: 1/1;
  max-width: 100%;

  font-weight: $weightGeologica-black;
  font-size: rem(25px);
  text-transform: uppercase;
  span {
    @include hyphenate();
    text-align: center;
    display: block;
    border-radius: 100%;
    aspect-ratio: 1/1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include grid-breakpoint--xs {
    font-size: rem(14px);
  }

  @include grid-breakpoint--s {
    font-size: rem(22px);
  }

  @include grid-breakpoint--m {
    font-size: rem(25px);
  }

}

.button--bronze {
  color: $colorBlack;
  span {
    background-color: $colorBronze;
  }
}

.button--black {
  color: $colorBronze;
  span {
    background-color: $colorBlack;
  }
}