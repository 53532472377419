$gridBreakpoint_l: 740px;
$gridBreakpoint_m: 740px;
$gridBreakpoint_s: 500px;
$gridBreakpoint_xs: 390px;

$navBreakpoint_m: 1260px;
$navBreakpoint_s: 800px;

$footerBreakpoint_m: 1150px;
$footerBreakpoint_s: 625px;

$col2Breakpoint_s: 730px;

@mixin grid-breakpoint--m {
  @media only screen and (min-width: $gridBreakpoint_m) {
    @content
  }
}

@mixin grid-breakpoint--s {
  @media only screen and (min-width: $gridBreakpoint_s) {
    @content
  }
}

@mixin grid-breakpoint--xs {
  @media only screen and (min-width: $gridBreakpoint_xs) {
    @content
  }
}

@mixin nav-breakpoint--s {
  @media only screen and (min-width: $navBreakpoint_s) {
    @content
  }
}

@mixin nav-breakpoint--m {
  @media only screen and (min-width: $navBreakpoint_m) {
    @content
  }
}

@mixin footer-breakpoint--s {
  @media only screen and (min-width: $footerBreakpoint_s) {
    @content
  }
}

@mixin footer-breakpoint--m {
  @media only screen and (min-width: $footerBreakpoint_m) {
    @content
  }
}

@mixin col2-breakpoint--s {
  @media only screen and (min-width: $col2Breakpoint_s) {
    @content
  }
}