.module--text {
  @include inner--m();
  color: $colorWhite;

  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    margin-top: 0;
    padding-left: 1.2rem;
    margin-bottom: 1rem;
  }

  h1, h2 {
    color: $colorBronze;
    text-transform: lowercase;
    font-size: rem(50px);
    line-height: 1.1;
    @include hyphenate;
  }

  h3 {
    color: $colorBronze;
    // text-transform: lowercase;
    font-size: rem(25px);
    line-height: 1.1;
    @include hyphenate;
    margin-bottom: .8rem;
  }

  a {
    text-decoration: underline;
    &:hover {
      color: $colorBronze;
    }
  }

}