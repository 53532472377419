$margin-s: 60px;
$margin-m: 80px;
$margin-l: 120px;

.module--text {
  margin-bottom: $margin-s;
  @include grid-breakpoint--xs {
    margin-bottom: $margin-m;
  }
  @include grid-breakpoint--m {
    margin-bottom: $margin-l;
  }
}

h1, h2 {
  margin-bottom: 20px;
  @include grid-breakpoint--xs {
    margin-bottom: 40px;
  }
  @include grid-breakpoint--m {
    margin-bottom: 55px;
  }
}

p + h2 {
  margin-top: $margin-s;
  @include grid-breakpoint--xs {
    margin-top: $margin-m;
  }
  @include grid-breakpoint--m {
    margin-top: $margin-l;
  }
}

p + h3 {
  margin-top: 2.2rem;;
}

.module--contact {
  .module--contact__griditem:first-child {
    margin-bottom: $margin-s;
    @include grid-breakpoint--xs {
      margin-bottom: $margin-m;
    }
    @include grid-breakpoint--m {
      margin-bottom: $margin-l;
    }
    @include col2-breakpoint--s {
      margin-bottom: 0;
    }
  }
}