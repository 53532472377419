// lock body
.nav-active {
  overflow-y: hidden;
  @include nav-breakpoint--s {
    overflow-y: scroll;
  }
}

.globalnav {
  // display: none;
  background-color: $colorBlack;
  z-index: 10;
  position: fixed;
  top: -120%;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &.open {
    top: 0;
    max-height: 100%;
    overflow: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


  @include nav-breakpoint--s {
    all: revert;
  }
}


.globalnav__list {
  @include nav-breakpoint--s {
    display: flex;
    justify-content: center;

  }
  @include nav-breakpoint--m {
    justify-content: flex-end;
  }
}

.globalnav__item {
  text-transform: lowercase;
  margin-bottom: 12px;

  @include nav-breakpoint--s {
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @include nav-breakpoint--m {
    margin-left: 50px;
    margin-right: 0;
    &:first-child {
      margin-left: 0;
    }
  }

}

.globalnav__link:hover {
  color: $colorWhite;
}