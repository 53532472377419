// Set core body defaults
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  min-height: 100vh;
}

// Prevent Animations onLoad
.preload-transitions * {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

/* https://csswizardry.com/2023/09/the-ultimate-lqip-lcp-technique/ */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 1rem;
}

//! BE NICE!

:focus {
  outline: 3px solid blue;
}

:focus:not(:focus-visible) {
  outline: 0;
}

:focus-visible {
  outline: 3px solid blue;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}