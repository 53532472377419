.module--contact {

  @include col2-breakpoint--s {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $gut;
  }

}

.form__submit--content {
  font-size: rem(25px);
}