body {
  font-family: $fontGeologica;
  font-weight: $weightGeologica-light;
  line-height: 1.46;
  font-size: rem(20px);
  color: $colorBronze;
  @include letterSpacingXD(50, 20);
}

a {
  transition: all .3s ease;
}