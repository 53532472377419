@mixin inner--xl {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: $col12 + 2*$content-padding;
}

@mixin inner--l {
  margin-right: auto;
  margin-left: auto;
  padding-right: $content-padding;
  padding-left: $content-padding;
  width: 100%;
  max-width: $col12 + 2*$content-padding;
}

@mixin inner--m {
  margin-right: auto;
  margin-left: auto;
  padding-right: $content-padding;
  padding-left: $content-padding;
  width: 100%;
  max-width: $col6 + 2*$content-padding;
}