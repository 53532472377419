//! RotateY

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.rotateY .button__link span {
  animation: rotateY 5.5s infinite;
   &:hover {
    // animation: none;
  }
}

//! RotateX

@keyframes rotateX {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(360deg);
  }
}

.rotateX .button__link span {
  animation: rotateX 5.5s infinite;
   &:hover {
    // animation: none;
  }
}

//! Pulse

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
  }
    100% {
    transform: scale(.9);
  }
}

.pulse .button__link span {
  animation: pulse 1.6s infinite;
  &:hover {
    // animation: none;
  }
}